<template>
    <div class="login">
        <div class="from-wrap">
            <h2>脑健康家小程序管理系统</h2>
            <Form ref="loginData" :model="loginData" :rules="ruleValidate">
                <FormItem prop="username">
                    <i-input type="text" prefix="ios-contact" size="large" v-model="loginData.username" placeholder="请输入登录账号"></i-input>
                </FormItem>
                <FormItem prop="password">
                    <i-input type="password" prefix="ios-lock-outline" size="large" v-model="loginData.password" placeholder="请输入密码"></i-input>
                </FormItem>
                <FormItem class="form-footer">
                    <Button type="primary" size="large" @click="handleSubmit('loginData')" style="width: 100%">登 录</Button>
                </FormItem>
            </Form>
        </div>
        <div class="footer">
            <!--<div class="footer-copyright">Copyright © 2020 xxxxx科技有限公司</div>-->
        </div>
    </div>
</template>
<script>
    import { setCookieToten, setCookieUser } from '@js/session.sg';
    export default {
        data() {
            return {
                loginData: {
                    username: '',
                    password: ''
                },
                ruleValidate: {
                    username: [
                        {
                            required: true,
                            message: '账号不能为空',
                            trigger: 'blur'
                        },
                        {
                            min: 3,
                            max: 16,
                            message: '账号长度3-16个字符',
                            trigger: 'blur'
                        }
                    ],
                    password: [
                        {
                            required: true,
                            message: '密码不能为空',
                            trigger: 'blur'
                        },
                        {
                            type: 'string',
                            min: 6,
                            max: 16,
                            message: '密码长度6-16个字符',
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },
        methods: {
            handleSubmit(name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        let that = this;
                        this.$http.post('/oauth/admin/pc/login', this.$http.adornData(this.loginData, false, 'form'))
                            .then((res) => {
                                console.log(res);
                                if (res.code === 1) {
                                    setCookieToten({ 'access_token': res.data.access_token });
                                    that.$http.get('/oauth/admin/info/' + res.data.access_token)
                                        .then((res) => {
                                            if (res.code === 1) {
                                                setCookieUser(res.data);
                                                that.$router.push({ path: '/main' });
                                            } else {
                                                this.$Message.success(res.msg);
                                            }
                                        }).catch(err => {
                                        console.log(err);
                                    });
                                } else {
                                    this.$Message.warning(res.msg);
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }

                })
            },
            handleReset(name) {
                this.$refs[name].resetFields();
            },
            login: function () {
                this.$router.push('/')
            }
        }
    }
</script>
<style>
    html, body {
        width: 100%;
        height: 100%;
        background-color: #f5f7f9;
    }

    .login {
        width: 100vw;
        height: 100vh;
        /*background-color: #1c2438;*/
        position: relative;
    }
    .login .from-wrap {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -40%);
        /*margin-left: -200px;*/
        /*margin-top: -150px;*/
        width: 400px;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px 30px;
    }

    .login h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .login FormItem {
        margin-bottom: 15px;
    }

    .login .form-footer {
        /*text-align: right;*/
    }

    .ivu-form-item-required .ivu-form-item-label:before {
        display: none;
    }
    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: 30px;
    }
    .footer-copyright {
        color: #808695;
        font-size: 14px;
        text-align: center;
    }
</style>
